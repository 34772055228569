import React from 'react'
import MainMenu from '../../components/mainMenu/MainMenu';
import Footer from '../../components/footer/Footer';

export default function PolicyPage() {
  return (
    <div className="homePage">
      <MainMenu />

      <div className="homePageTextBlock">
        <span className="h1Title">ZÁSADY ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</span>
        <div>
          <p>Webová stránka: penzionhoustka.cz</p>
          <p>Datum účinnosti: 1. 7. 2024</p>

          <b>1. Úvod</b>
          <p>Tento dokument stanoví zásady zpracování osobních údajů návštěvníků webové stránky penzionhoustka.cz (dále jen "webová stránka"). Jsme odhodláni chránit vaše osobní údaje a zajistit, že jsou zpracovávány v souladu s platnými právními předpisy na ochranu osobních údajů.</p>

          <b>2. Správce osobních údajů</b>
          <p>Správcem osobních údajů je Penzion Houstka, se sídlem Houštka 375/3, 250 01 Brandýs nad Labem-Stará Boleslav 1, IČ: 16555082. V případě jakýchkoliv dotazů nebo žádostí týkajících se zpracování vašich osobních údajů nás můžete kontaktovat na e-mailové adrese info@penzionhoustka.cz.</p>

          <b>3. Zpracovávané osobní údaje</b>
          <p>Zpracováváme následující osobní údaje:</p>
          <ul>
            <li>Kontaktní údaje: jméno, příjmení, e-mailová adresa, telefonní číslo.</li>
            <li>Údaje o rezervacích: informace o vašich rezervacích a pobytech v našem penzionu.</li>
            <li>Technické údaje: IP adresa, typ prohlížeče, informace o zařízení, z kterého přistupujete na webovou stránku.</li>
          </ul>

          <b>4. Účely zpracování osobních údajů</b>
          <p>Vaše osobní údaje zpracováváme pro následující účely:</p>
          <ul>
            <li>Poskytování služeb: Zajištění rezervací a poskytování ubytovacích služeb.</li>
            <li>Komunikace: Komunikace s vámi ohledně vašich rezervací, odpovídání na vaše dotazy a poskytování informací o našich službách.</li>
            <li>Zlepšování služeb: Analýza a zlepšování našich služeb a webové stránky.</li>
            <li>Marketing: Zasílání marketingových materiálů a nabídek, pokud jste k tomu udělili souhlas.</li>
            <li>Právní povinnosti: Plnění zákonných povinností a řešení právních sporů.</li>
          </ul>

          <b>5. Právní základ zpracování</b>
          <p>Vaše osobní údaje zpracováváme na základě následujících právních důvodů:</p>
          <ul>
            <li>Plnění smlouvy: Zpracování osobních údajů je nezbytné pro plnění smlouvy, kterou jste s námi uzavřeli.</li>
            <li>Souhlas: Pokud jste nám udělili souhlas se zpracováním osobních údajů pro konkrétní účely.</li>
            <li>Právní povinnosti: Zpracování osobních údajů je nezbytné pro plnění našich zákonných povinností.</li>
            <li>Oprávněné zájmy: Zpracování je nezbytné pro účely našich oprávněných zájmů, pokud nepřeváží vaše základní práva a svobody.</li>
          </ul>

          <b>6. Sdílení osobních údajů</b>
          <p>Vaše osobní údaje můžeme sdílet s následujícími subjekty:</p>
          <ul>
            <li>Poskytovatelé služeb: Třetí strany, které nám poskytují služby, jako jsou IT služby, marketingové služby, platební brány apod.</li>
            <li>Právní a regulační orgány: Orgány veřejné správy, pokud je to vyžadováno zákonem nebo pro ochranu našich práv.</li>
          </ul>

          <b>7. Přenos osobních údajů do třetích zemí</b>
          <p>Vaše osobní údaje nepřenášíme do třetích zemí mimo Evropský hospodářský prostor (EHP).</p>

          <b>8. Doba uchovávání osobních údajů</b>
          <p>Vaše osobní údaje uchováváme pouze po nezbytně nutnou dobu, která je stanovena příslušnými právními předpisy a našimi vnitřními politikami.</p>

          <b>9. Vaše práva</b>
          <p>Máte následující práva týkající se vašich osobních údajů:</p>
          <ul>
            <li>Právo na přístup: Můžete požádat o kopii vašich osobních údajů, které zpracováváme.</li>
            <li>Právo na opravu: Můžete požádat o opravu nepřesných nebo neúplných osobních údajů.</li>
            <li>Právo na výmaz: Můžete požádat o výmaz vašich osobních údajů za určitých okolností.</li>
            <li>Právo na omezení zpracování: Můžete požádat o omezení zpracování vašich osobních údajů.</li>
            <li>Právo na přenositelnost: Můžete požádat o přenos vašich osobních údajů k jinému správci.</li>
            <li>Právo vznést námitku: Můžete vznést námitku proti zpracování vašich osobních údajů na základě oprávněného zájmu.</li>
            <li>Právo na odvolání souhlasu: Pokud jste udělili souhlas se zpracováním, můžete jej kdykoliv odvolat.</li>
          </ul>

          <b>10. Zabezpečení osobních údajů</b>
          <p>Přijímáme vhodná technická a organizační opatření k zajištění bezpečnosti vašich osobních údajů a ochraně před neoprávněným přístupem, ztrátou, zničením nebo poškozením.</p>

          <b>11. Změny těchto zásad</b>
          <p>Tyto zásady můžeme čas od času aktualizovat. O jakýchkoliv změnách vás budeme informovat zveřejněním nové verze těchto zásad na naší webové stránce.</p>

          <b>12. Kontakt</b>
          <p>Pokud máte jakékoliv dotazy nebo žádosti týkající se těchto zásad nebo zpracování vašich osobních údajů, kontaktujte nás na e-mailové adrese info@penzionhoustka.cz.</p>

          <p>Tento dokument je účinný od 1. 7. 2024.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
