import { MessageBarTitle } from '@fluentui/react-components';
import { EMAIL_JS_OQY_CONFIRMATION_TEMPLATE_ID_V2, EMAIL_JS_PUBLIC_KEY, EMAIL_JS_PUBLIC_KEY_V2, EMAIL_JS_RESERVATION_CONFIRMED_TEMPLATE_ID, EMAIL_JS_RESERVATION_CREATED_TEMPLATE_ID, EMAIL_JS_SERVICE_ID, EMAIL_JS_SERVICE_ID_V2 } from './constants';
import axios from 'axios';

/**
 * Function to redirect to a specific URL
 * @param url url address or path
 * @param openInNewTab boolean to open the URL in a new tab
 */
export function redirectToUrl(url: string, openInNewTab?: boolean): void {
  window.history.pushState({}, "", url);

  if (openInNewTab) {
    window.open(url, "_blank");
  } else {
    window.location.replace(url);
  }
};

/**
 * Function to get a parameter from the URL
 * @param param parameter name
 * @returns parameter value or null
 */
export function getParametrFromUrl(param: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

export async function sendReservationCreatedOgyEmail(): Promise<void> {
  var data = {
    service_id: EMAIL_JS_SERVICE_ID_V2,
    template_id: EMAIL_JS_OQY_CONFIRMATION_TEMPLATE_ID_V2,
    user_id: EMAIL_JS_PUBLIC_KEY_V2
  };

  try {
    const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data);
    console.log(response.data);
  } catch (e) {
    console.error(e);
  }
}

export async function sendReservationCreatedEmail(mailTo: string, resId: string): Promise<void> {
  var data = {
    service_id: EMAIL_JS_SERVICE_ID,
    template_id: EMAIL_JS_RESERVATION_CREATED_TEMPLATE_ID,
    user_id: EMAIL_JS_PUBLIC_KEY,
    template_params: {
        'mailTo': mailTo,
        'resId': resId
    }
  };

  try {
    const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data);
    console.log(response.data);
  } catch (e) {
    console.error(e);
  }
}

export async function sendReservationConfirmationEmail(mailTo: string, resId: string): Promise<void> {
  var data = {
    service_id: EMAIL_JS_SERVICE_ID,
    template_id: EMAIL_JS_RESERVATION_CONFIRMED_TEMPLATE_ID,
    user_id: EMAIL_JS_PUBLIC_KEY,
    template_params: {
        'mailTo': mailTo,
        'resId': resId
    }
  };

  try {
    const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data);
    console.log(response.data);
  } catch (e) {
    console.error(e);
  }
}