import React, { useContext, useEffect, useState } from 'react';
import './ReservationPage.css';
import 'react-slideshow-image/dist/styles.css';
import MainMenu from '../../components/mainMenu/MainMenu';
import Footer from '../../components/footer/Footer';
import { Field } from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { AppContext } from '../..';
import { collection, getDocs } from 'firebase/firestore';
import { CZECH_DATE_PICKER_STRINGS, DUMMY_ROOM_FILE_NAME, FB_RESERVATIONS_COLLECTION_NAME, FB_ROOMS_COLLECTION_NAME, FB_STORAGE_BUCKET_URL, ROUTER_RESERVATION_CONFIRMATION_PAGE_PATH } from '../../helpers/constants';
import { IFbRoom, IFbRoomImage, IReservation } from '../../interfaces/interface';
import { getStorage, listAll, ref } from 'firebase/storage';
import { Slide } from 'react-slideshow-image';
import { addDays } from '@fluentui/react';
import { FaBed } from "react-icons/fa";
import { CgRemove } from "react-icons/cg";
import { redirectToUrl } from '../../helpers/functions';


export default function ReservationPage(): JSX.Element {
  const ctx = useContext(AppContext);

  const [allRooms, setAllRooms] = useState<IFbRoom[]>([]);
  const [allRoomImages, setAllRoomImages] = useState<IFbRoomImage[]>([]);
  const [roomsForReservation, setRoomsForReservation] = useState<IFbRoom[]>([]);
  const [selectedArrivalDate, setSelectedArrivalDate] = useState<Date>();
  const [selectedDepartureDate, setSelectedDepartureDate] = useState<Date>();
  const [reservations, setReservations] = useState<IReservation[]>([]);


  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const fbAllRooms = await getDocs(collection(ctx.db, FB_ROOMS_COLLECTION_NAME));
      const fbReservations = await getDocs(collection(ctx.db, FB_RESERVATIONS_COLLECTION_NAME));

      setReservations(fbReservations.docs.map((doc) => JSON.parse(doc.data().reservation)));

      setAllRooms(fbAllRooms.docs.map<IFbRoom>((doc) => { return {
        id: doc.id,
        roomNumber: doc.data().roomNumber,
        title: doc.data().title,
        description: doc.data().description,
        numberOfBeds: doc.data().numberOfBeds,
      }; }));

      fbAllRooms.docs.forEach(async (doc) => {
        const files = (await listAll(await ref(getStorage(), `rooms/${doc.id}`))).items;
        const images: IFbRoomImage[] = files.filter((file) => file.name !== DUMMY_ROOM_FILE_NAME).map((file) => {
          return {
            roomId: doc.id,
            url: `${FB_STORAGE_BUCKET_URL}${encodeURIComponent(file.fullPath)}?alt=media`,
            name: file.name
          } as IFbRoomImage;
        });

        setAllRoomImages(prev => [...prev, ...images]);
      });
    };

    setSelectedArrivalDate(addDays(new Date(), 1));
    setSelectedDepartureDate(addDays(new Date(), 6));
    fetchData().catch((e) => console.error(e));
  }, [ctx.db]);

  const addRoomToReservation = (room: IFbRoom) => {
    setRoomsForReservation([...roomsForReservation, room]);
  };

  const removeRoomFromReservation = (room: IFbRoom) => {
    setRoomsForReservation(roomsForReservation.filter((r) => r !== room));
  };

  const onArrivalDateChange = (date: Date | null | undefined): void => {
    if (date) {
      setSelectedArrivalDate(date);
    }
  };

  const onDepartureDateChange = (date: Date | null | undefined): void => {
    if (date) {
      setSelectedDepartureDate(date);
    }
  };

  const createReservation = () => {
    const reservation: IReservation = {
      arrivalDate: selectedArrivalDate!,
      departureDate: selectedDepartureDate!,
      rooms: roomsForReservation
    };

    localStorage.setItem('reservation', JSON.stringify(reservation));
    redirectToUrl(ROUTER_RESERVATION_CONFIRMATION_PAGE_PATH);
  };

  const isRoomAvailable = (room: IFbRoom, reservationsArray: IReservation[], dateFrom: Date, dateTo: Date) => {
    console.log("🚀 ~ file: ReservationPAge.tsx:96 ~ isRoomAvailable ~ room:", room, reservationsArray, dateFrom, dateTo);
    // Convert date strings to Date objects for comparison
    const from = new Date(dateFrom);
    const to = new Date(dateTo);

    // Iterate over the reservations to check for conflicts
    for (const reservation of reservationsArray) {
      if (reservation.rooms.filter((r) => r.id === room.id).length >= 1) {
        const resFrom = new Date(reservation.arrivalDate);
        const resTo = new Date(reservation.departureDate);

        // Check if the requested date range overlaps with the reservation date range
        if (from < resTo && to > resFrom) {
          // There's a conflict
          //return false;

          //TODO: Musí se upravit pro pouze potvrzené rezervace
          return true;
        }
      }
    }

    // No conflicts found, the room is available
    return true;
  };

  return (
    <div className="homePage">
      <MainMenu />

      <div className="homePageTextBlock">
        <span className="h1Title">POPTÁVKA <strong>UBYTOVÁNÍ</strong></span>

        <div className="reservationDatePicker">
          <Field label="Datum příjezdu">
            <DatePicker
              placeholder="Vyberte datum ..."
              lang='cs-CZ'
              firstDayOfWeek={1}
              value={selectedArrivalDate}
              onSelectDate={onArrivalDateChange}
              strings={CZECH_DATE_PICKER_STRINGS}
              minDate={addDays(new Date(), 1)}
              formatDate={(date) => date?.toLocaleDateString('cs-CZ') || ''}
            />
          </Field>
          <Field label="Datum odjezdu">
            <DatePicker
              placeholder="Vyberte datum ..."
              lang='cs-CZ'
              firstDayOfWeek={1}
              value={selectedDepartureDate}
              onSelectDate={onDepartureDateChange}
              strings={CZECH_DATE_PICKER_STRINGS}
              minDate={addDays(new Date(), 2)}
              formatDate={(date) => date?.toLocaleDateString('cs-CZ') || ''}
            />
          </Field>
        </div>

        {(roomsForReservation.length > 0) && <div className="reservation">
          <div className="reservations">
            <div className='reservationHeader'>
              <span className="roomTitle">Vaše poptávka</span>
              <span>{selectedArrivalDate?.toLocaleDateString('cs-CZ')} - {selectedDepartureDate?.toLocaleDateString('cs-CZ')}</span>
            </div>
            {roomsForReservation.map((room, index) => {
              return (
                <div key={index} className="reservationRoom">
                  <div>
                    <span>{room.title}</span>
                    <FaBed size={18} />
                    <span>{room.numberOfBeds}</span>
                  </div>
                  <CgRemove size={18} onClick={() => removeRoomFromReservation(room)} />
                </div>
              );
            })}
            <button className='myButton' onClick={() => createReservation()}>Vytvořit poptávku</button>
          </div>
        </div>}

        <div className="roomsForReservation">
          {allRooms.filter((room) => isRoomAvailable(room, reservations, selectedArrivalDate || new Date(), selectedDepartureDate || new Date())).filter((room) => !roomsForReservation.includes(room)).map((room, index) => {
            return (
              <div key={index} className="room">
                <div>
                  <span className="roomTitle">{room.title}</span>
                  <div key={index} className="reservationRoom">
                    <div>
                      <span>{room.title}</span>
                      <FaBed size={18} />
                      <span>{room.numberOfBeds}</span>
                    </div>
                  </div>
                  <p>450 Kč s DPH /osoba/noc (+ 100 Kč v případě jednodenního přespání)</p>
                  <p>{room.description}</p>
                  <button className='myButton' onClick={() => addRoomToReservation(room)}>Poptat pokoj</button>
                </div>
                <Slide key={room.id}>
                  {allRoomImages.filter((image) => image.roomId === room.id).map((img) => {
                    console.log("🚀 ~ file: ReservationPAge.tsx:193 ~ {allRoomImages.filter ~ img:", img);
                    return (
                      <div className="each-slide-effect">
                        <img src={img.url} alt={img.name} className='imageSliderPicture' loading='lazy' />
                        {/*<div style={{ 'backgroundImage': `url(${img.url})` }}>
                          <span></span>
                        </div>*/}
                      </div>
                    );
                  })}
                </Slide>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}
