import React, { Context, createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { IAppContext } from './interfaces/interface';
import emailjs from '@emailjs/browser';
import { EMAIL_JS_PUBLIC_KEY } from './helpers/constants';

export const AppContext: Context<IAppContext> = createContext({} as IAppContext);

emailjs.init({
  publicKey: EMAIL_JS_PUBLIC_KEY,
  limitRate: {
    // Set the limit rate for the application
    id: 'app',
    // Allow 1 request per 10s
    throttle: 10000,
  },
});

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAytX2HSmMklN0kehFs5noRwm-Yyhp18t4",
  authDomain: "ubytovna-9f1c2.firebaseapp.com",
  projectId: "ubytovna-9f1c2",
  storageBucket: "ubytovna-9f1c2.appspot.com",
  messagingSenderId: "419995280864",
  appId: "1:419995280864:web:baa864bd987676fe2c3785",
  measurementId: "G-9FBBSJMGZN"
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

root.render(
  <React.StrictMode>
    <FluentProvider theme={webLightTheme}>
      <AppContext.Provider value={{ auth, db, storage }}>
        <App />
      </AppContext.Provider>
    </FluentProvider>
  </React.StrictMode>
);