import React from 'react';
import './MainMenu.css';
import { redirectToUrl } from '../../helpers/functions';
import { ROUTER_ACTION_PAGE_PATH, ROUTER_CONTACT_PAGE_PATH, ROUTER_HOME_PAGE_PATH, ROUTER_RESERVATION_PAGE_PATH } from '../../helpers/constants';

export default function MainMenu(): JSX.Element {
  return (
    <div className="homePageMainMenu">
      <span onClick={() => redirectToUrl(ROUTER_HOME_PAGE_PATH)}>DOMŮ</span>
      <span onClick={() => redirectToUrl(ROUTER_ACTION_PAGE_PATH)}>AKCE</span>
      <span onClick={() => redirectToUrl(ROUTER_RESERVATION_PAGE_PATH)}>POPTÁVKA</span>
      <span onClick={() => redirectToUrl(ROUTER_CONTACT_PAGE_PATH)}>KONTAKTY</span>
    </div>
  );
}
