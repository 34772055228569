import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../..';
import { collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { FB_RESERVATIONS_COLLECTION_NAME } from '../../../helpers/constants';
import { IFbRoom, IReservationData } from '../../../interfaces/interface';
import { Caption1, Card, CardHeader, tokens, Text, Button, Divider, Dialog, DialogSurface, Label, Input, Textarea, DialogActions, useId, InputOnChangeData, TextareaOnChangeData } from '@fluentui/react-components';
import { AddCircle20Regular } from "@fluentui/react-icons";
import { Stack } from '@fluentui/react';
import { FaBed } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { useBoolean } from '@fluentui/react-hooks';
import { sendReservationConfirmationEmail } from '../../../helpers/functions';

export default function Reservations(): JSX.Element {
  const ctx = useContext(AppContext);

  const firstNameId = useId("input-first-name");
  const lastNameId = useId("input-last-name");
  const phoneNumberId = useId("input-phone-number");
  const emailId = useId("input-email");
  const noteId = useId("input-note");
  const organizationNameId = useId("input-organization-name");
  const icoId = useId("input-ico");
  const streetId = useId("input-street");
  const pscId = useId("input-psc");
  const cityId = useId("input-city");

  const [reservations, setReservations] = useState<IReservationData[]>([]);
  const [openDialog, { toggle: toggleOpenDialog }] = useBoolean(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [ico, setIco] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [psc, setPsc] = useState<string>("");
  const [city, setCity] = useState<string>("");
  
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const fbReservations = await getDocs(collection(ctx.db, FB_RESERVATIONS_COLLECTION_NAME));
      
      setReservations(fbReservations.docs.map<IReservationData>((doc) => {
        return {
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          phoneNumber: doc.data().phoneNumber,
          organization: doc.data().organization,
          ico: doc.data().ico,
          street: doc.data().street,
          psc: doc.data().psc,
          city: doc.data().city,
          note: doc.data().note,
          reservation: doc.data().reservation,
          confirmation: doc.data().confirmation
        }
      }));
    };

    fetchData().catch((e) => console.error(e));
  }, [ctx.db]);

  const closeDialog = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setEmail("");
    setNote("");
    setOrganizationName("");
    setIco("");
    setStreet("");
    setPsc("");
    setCity("");
    toggleOpenDialog();
  };

  const onAddNewReservationClick = async () => {
    //TODO: Add new reservation
  };

  const deleteReservation = async (reservation: IReservationData) => {
    const roomRef = doc(collection(ctx.db, FB_RESERVATIONS_COLLECTION_NAME), reservation?.id);
    await deleteDoc(roomRef);

    setReservations(reservations.filter((res) => res.id !== reservation.id));
  };

  const confirmReservation = async (reservation: IReservationData) => {
    const roomRef = doc(collection(ctx.db, FB_RESERVATIONS_COLLECTION_NAME), reservation?.id);
    await updateDoc(roomRef, {
      confirmation: true
    });

    setReservations(reservations.map((res) => {
      if (res.id === reservation.id) {
        return {
          ...res,
          confirmation: true
        };
      }

      return res;
    }));

    await sendReservationConfirmationEmail(reservation.email, reservation.id ?? "");
  };

  return (
    <div>
      <div className="allRoomsListHeader">
        <span className="title">POPTÁVKY</span>
        {/*<Button appearance="primary" icon={<AddCircle20Regular />} iconPosition='after' onClick={toggleOpenDialog}>Přidat poptávku</Button>*/}
      </div>

      <div className="allRoomsList">
        {reservations.filter((res) => new Date(JSON.parse(res.reservation).departureDate).getTime() > new Date().getTime()).map((reservation, index) => {
          return (
            <Card key={index}>
              <CardHeader
                header={
                  <Text weight="semibold">
                    {reservation.firstName} {reservation.lastName} / {new Date(JSON.parse(reservation.reservation).arrivalDate).toLocaleDateString("cs-CZ")} - {new Date(JSON.parse(reservation.reservation).departureDate).toLocaleDateString("cs-CZ")} / <span className="reservationConfirmedText">{reservation.confirmation ? "Potvrzeno" : ""}</span>
                  </Text>
                }
                description={
                  <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                    {JSON.parse(reservation.reservation).rooms.map((room: IFbRoom, index: number) => {
                      return (
                        <div key={index} className="reservationRoom">
                          <div>
                            <span>{room.title}</span>
                            <FaBed size={18} />
                            <span>{room.numberOfBeds}</span>
                          </div>
                        </div>
                      );
                    })}
                  </Caption1>
                }
                action={
                  <Stack horizontal tokens={{ childrenGap: "10" }} styles={{ root: { alignItems: "center" } }}>
                    {!reservation.confirmation && <Button size='small' appearance='primary' onClick={() => confirmReservation(reservation)}>Potvrdit rezervaci</Button>}
                    <Button size='small' appearance='secondary' onClick={() => deleteReservation(reservation)}>Smazat rezervaci</Button>
                  </Stack>
                } />
              <div>
                <Text weight="semibold">Kontaktní údaje</Text>
                <div className="reservationContactInformation">
                  <span><MdEmail /><a href={`mailto:${reservation.email}`}>{reservation.email}</a></span>
                  <span><FaPhoneAlt /><a href={`tel:${reservation.phoneNumber}`}>{reservation.phoneNumber}</a></span>
                </div>

                <Text weight="semibold">Fakturační údaje</Text>
                <div className="reservationFacInformation">
                  {reservation.organization && <span>{reservation.organization}</span>}
                  {reservation.ico && <span>IČ: {reservation.ico}</span>}
                  <span>Adresa: {reservation.street}, {reservation.psc} {reservation.city}</span>
                </div>
              </div>
            </Card>
          );
        })}
      </div>

      {(reservations.filter((res) => new Date(JSON.parse(res.reservation).departureDate).getTime() < new Date().getTime()).length > 0) && <Divider className="divider">Minulé rezervace</Divider>}

      <div className="allRoomsList">
        {reservations.filter((res) => new Date(JSON.parse(res.reservation).departureDate).getTime() < new Date().getTime()).map((reservation, index) => {
          return (
            <Card key={index}>
              <CardHeader
                header={
                  <Text weight="semibold">
                    {reservation.firstName} {reservation.lastName} / {new Date(JSON.parse(reservation.reservation).arrivalDate).toLocaleDateString("cs-CZ")} - {new Date(JSON.parse(reservation.reservation).departureDate).toLocaleDateString("cs-CZ")} / <span className="reservationConfirmedText">{reservation.confirmation ? "Potvrzeno" : ""}</span>
                  </Text>
                }
                description={
                  <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                    {JSON.parse(reservation.reservation).rooms.map((room: IFbRoom, index: number) => {
                      return (
                        <div key={index} className="reservationRoom">
                          <div>
                            <span>{room.title}</span>
                            <FaBed size={18} />
                            <span>{room.numberOfBeds}</span>
                          </div>
                        </div>
                      );
                    })}
                  </Caption1>
                }
                action={
                  <Stack horizontal tokens={{ childrenGap: "10" }} styles={{ root: { alignItems: "center" } }}>
                    {!reservation.confirmation && <Button size='small' appearance='primary' onClick={() => confirmReservation(reservation)}>Potvrdit poptávku</Button>}
                    <Button size='small' appearance='secondary' onClick={() => deleteReservation(reservation)}>Smazat poptávku</Button>
                  </Stack>
                } />
              <div>
                <Text weight="semibold">Kontaktní údaje</Text>
                <div className="reservationContactInformation">
                  <span><MdEmail /><a href={`mailto:${reservation.email}`}>{reservation.email}</a></span>
                  <span><FaPhoneAlt /><a href={`tel:${reservation.phoneNumber}`}>{reservation.phoneNumber}</a></span>
                </div>

                <Text weight="semibold">Fakturační údaje</Text>
                <div className="reservationFacInformation">
                  {reservation.organization && <span>{reservation.organization}</span>}
                  {reservation.ico && <span>IČ: {reservation.ico}</span>}
                  <span>Adresa: {reservation.street}, {reservation.psc} {reservation.city}</span>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
      <Dialog open={openDialog}>
        <DialogSurface>
          <span className="title">Přidat poptávku</span>
          <br /><br />
          <Stack tokens={{ childrenGap: "5" }} horizontal={false}>
            <Label htmlFor={firstNameId}>Jméno</Label>
            <Input id={firstNameId} type='text' appearance='outline' value={firstName} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setFirstName(data.value)} />

            <Label htmlFor={lastNameId}>Příjmení</Label>
            <Input id={lastNameId} type='text' appearance='outline' value={lastName} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setLastName(data.value)} />

            <Label htmlFor={phoneNumberId}>Telefonní číslo</Label>
            <Input id={phoneNumberId} type='text' appearance='outline' value={phoneNumber} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setPhoneNumber(data.value)} />

            <Label htmlFor={emailId}>E-mail</Label>
            <Input id={emailId} type='text' appearance='outline' value={email} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setEmail(data.value)} />

            <Label htmlFor={noteId}>Poznámka</Label>
            <Textarea id={noteId} appearance='outline' value={note} onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => setNote(data.value)} rows={3} />

            <Label htmlFor={organizationNameId}>Jméno / Organizace</Label>
            <Input id={organizationNameId} type='text' appearance='outline' value={organizationName} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setOrganizationName(data.value)} />

            <Label htmlFor={icoId}>IČO</Label>
            <Input id={icoId} type='text' appearance='outline' value={ico} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setIco(data.value)} />

            <Label htmlFor={streetId}>Ulice a ČP</Label>
            <Input id={streetId} type='text' appearance='outline' value={street} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setStreet(data.value)} />

            <Label htmlFor={pscId}>PSČ</Label>
            <Input id={pscId} type='text' appearance='outline' value={psc} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setPsc(data.value)} />

            <Label htmlFor={cityId}>Město</Label>
            <Input id={cityId} type='text' appearance='outline' value={city} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setCity(data.value)} />
            <br /><br />
          </Stack>
          <DialogActions>
            <Button appearance="primary" onClick={onAddNewReservationClick}>Přidat</Button>
            <Button appearance="secondary" onClick={closeDialog}>Zrušit</Button>
          </DialogActions>
        </DialogSurface>
      </Dialog>
    </div>
  );
}
