import React, { useContext, useEffect, useState } from 'react';
import { addDoc, collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { IFbRoom } from '../../../interfaces/interface';
import { AppContext } from '../../..';
import { Button, Caption1, Card, CardHeader, Dialog, DialogActions, DialogSurface, Input, InputOnChangeData, Label, Text, Textarea, TextareaOnChangeData, tokens, useId } from '@fluentui/react-components';
import { DUMMY_ROOM_FILE_NAME, FB_ROOMS_COLLECTION_NAME } from '../../../helpers/constants';
import { AddCircle20Regular, DeleteRegular, Edit20Regular } from "@fluentui/react-icons";
import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { TRoomDialogType } from '../../../helpers/types';
import { ref, listAll, getMetadata, uploadBytes } from 'firebase/storage';

export interface IRoomsProps {
};

export default function Rooms(props: IRoomsProps): JSX.Element {
  const ctx = useContext(AppContext);

  const titleId = useId("input-title");
  const roomNumberId = useId("input-room-number");

  const [allRooms, setAllRooms] = useState<IFbRoom[]>([]);
  const [openDialog, { toggle: toggleOpenDialog }] = useBoolean(false);
  const [roomName, setRoomName] = useState<string>("");
  const [roomNumber, setRoomNumber] = useState<string>("");
  const [roomDescription, setRoomDescription] = useState<string>("");
  const [currentRoom, setCurrentRoom] = useState<IFbRoom | null>(null);
  const [dialogType, setDialogType] = useState<TRoomDialogType>("add");
  const [numberOfBeds, setNumberOfBeds] = useState<number>();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const fbAllRooms = await getDocs(collection(ctx.db, FB_ROOMS_COLLECTION_NAME));

      setAllRooms(fbAllRooms.docs.map((doc) => { return {
        id: doc.id,
        roomNumber: doc.data().roomNumber,
        title: doc.data().title,
        description: doc.data().description,
        numberOfBeds: doc.data().numberOfBeds
      } as IFbRoom; }));
    };

    fetchData().catch((e) => console.error(e));
  }, [ctx.db]);

  const closeDialog = () => {
    toggleOpenDialog();
    setRoomName("");
    setRoomNumber("");
    setRoomDescription("");
    setNumberOfBeds(undefined);
    setDialogType("add");
    setCurrentRoom(null);
  };

  const openEditDialog = (room: IFbRoom) => {
    setRoomName(room.title);
    setRoomNumber(room.roomNumber);
    setRoomDescription(room.description);
    setNumberOfBeds(room.numberOfBeds);
    setDialogType("edit");
    setCurrentRoom(room);

    toggleOpenDialog();
  };

  const createNewFolder = async (folderPath: string) => {
    try {
      const folderRef = ref(ctx.storage, folderPath); // Reference to the potential folder
  
      // Check if the path already exists (folder or file with the same name)
      const listResult = await listAll(folderRef);
  
      if (listResult.items.length === 0) {
        // Path doesn't exist, create a dummy file to simulate a folder
        const dummyContent = new Blob([], { type: 'text/plain' });
        const dummyRef = ref(folderRef, DUMMY_ROOM_FILE_NAME);
        await uploadBytes(dummyRef, dummyContent);
      } else {
        // Path already exists, handle the case (e.g., display message to user)
        const firstItem = listResult.items[0];
        const metadata = await getMetadata(firstItem);
        if (metadata.contentType === 'text/plain' && firstItem.name === '.dummy') {
          console.log("Folder already exists:", folderRef.toString());
        } else {
          console.warn("Path already exists as a file or non-folder item:", folderPath);
        }
      }
    } catch (error) {
      console.error("Error creating folder:", error);
      // Handle unexpected errors
    }
  };

  const onAddRoomClick = async () => {
    try {
      const response = await addDoc(collection(ctx.db, FB_ROOMS_COLLECTION_NAME), {
        title: roomName,
        roomNumber: roomNumber,
        description: roomDescription,
        numberOfBeds: numberOfBeds
      } as IFbRoom);

      await createNewFolder(`rooms/${response.id}`);
      setAllRooms([...allRooms, { id: response.id, title: roomName, roomNumber: roomNumber, description: roomDescription, numberOfBeds: Number(numberOfBeds)}]);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    closeDialog();
  };

  const onEditRoomClick = async () => {
    try {
      const roomRef = doc(collection(ctx.db, FB_ROOMS_COLLECTION_NAME), currentRoom?.id);
      await updateDoc(roomRef, {
        title: roomName,
        roomNumber: roomNumber,
        description: roomDescription,
        numberOfBeds: numberOfBeds
      });

      setAllRooms(allRooms.map((r) => {
        if (r.roomNumber === currentRoom?.roomNumber) {
          return {
            id: currentRoom?.id,
            title: roomName,
            roomNumber: roomNumber,
            description: roomDescription,
            numberOfBeds: numberOfBeds
          } as IFbRoom;
        } else {
          return r;
        }
      }));
    } catch (e) {
      console.error("Error updating document: ", e);
    }

    closeDialog();
  };

  const onDeleteRoomClick = async (room: IFbRoom) => {
    try {
      const roomRef = doc(collection(ctx.db, FB_ROOMS_COLLECTION_NAME), room.id);
      await deleteDoc(roomRef);
      setAllRooms(allRooms.filter((r) => r.roomNumber !== room.roomNumber));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  return (
    <div>
      <div className="allRoomsListHeader">
        <span className="title">POKOJE</span>
        <Button appearance="primary" icon={<AddCircle20Regular />} iconPosition='after' onClick={toggleOpenDialog}>Přidat pokoj</Button>
      </div>
      <div className="allRoomsList">
        {allRooms.map((room, index) => {
          return (
            <Card key={index}>
              <CardHeader
                header={
                  <Text weight="semibold">
                    {room.title}
                  </Text>
                }
                description={
                  <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                    Číslo pokoje: {room.roomNumber} / Počet postelí: {room.numberOfBeds}
                  </Caption1>
                }
                action={
                  <Stack horizontal tokens={{ childrenGap: "10" }} styles={{ root: { alignItems: "center" } }}>
                    <Edit20Regular style={{ transform: "scale(1.0)", cursor: "pointer" }} onClick={() => openEditDialog(room)} />
                    <DeleteRegular style={{ transform: "scale(1.3)", cursor: "pointer" }} onClick={() => onDeleteRoomClick(room)} />
                  </Stack>
                } />
              <p>{room.description}</p>
            </Card>
          );
        })}
      </div>
      <Dialog open={openDialog}>
        <DialogSurface>
          <span className="title">Přidat pokoj</span>
          <br /><br />
          <Stack tokens={{ childrenGap: "5" }} horizontal={false}>
            <Label htmlFor={titleId}>Jméno pokoje</Label>
            <Input id={titleId} type='text' appearance='outline' value={roomName} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setRoomName(data.value)} />

            <Label htmlFor={roomNumberId}>Číslo pokoje</Label>
            <Input id={roomNumberId} type='text' appearance='outline' value={roomNumber} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setRoomNumber(data.value)} />

            <Label htmlFor={roomNumberId}>Popis pokoje</Label>
            <Textarea id={roomNumberId} appearance='outline' value={roomDescription} onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => setRoomDescription(data.value)} />

            <Label htmlFor={titleId}>Počet postelí</Label>
            <Input id={titleId} type='number' appearance='outline' value={numberOfBeds?.toString()} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setNumberOfBeds(Number(data.value))} />
            <br /><br />
          </Stack>
          <DialogActions>
            {(dialogType === 'add') && <Button appearance="primary" onClick={onAddRoomClick}>Přidat</Button>}
            {(dialogType === 'edit') && <Button appearance="primary" onClick={onEditRoomClick}>Upravit</Button>}
            <Button appearance="subtle" onClick={closeDialog}>Zrušit</Button>
          </DialogActions>
        </DialogSurface>
      </Dialog>
    </div>
  );
}
