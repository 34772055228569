import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminPage from './pages/adminPage/AdminPage';
import HomePage from './pages/homePage/HomePage';
import LoginPage from './pages/loginPage/LoginPage';
import { ROUTER_ACTION_PAGE_PATH, ROUTER_CONTACT_PAGE_PATH, ROUTER_DASHBOARD_PAGE_PATH, ROUTER_DASHBOARD_RESERVATIONS_PAGE_PATH, ROUTER_DASHBOARD_ROOMS_PAGE_PATH, ROUTER_HOME_PAGE_PATH, ROUTER_LOGIN_PAGE_PATH, ROUTER_POLICY_PAGE_PATH, ROUTER_RESERVATION_CONFIRMATION_PAGE_PATH, ROUTER_RESERVATION_PAGE_PATH, ROUTER_RESERVATION_VIEW_PAGE_PATH } from './helpers/constants';
import ContactPage from './pages/contactPage/ContactPage';
import ReservationPage from './pages/reservationPage/ReservationPage';
import ReservationConfirmationPage from './pages/reservationConfirmationPage/ReservationConfirmationPage';
import ReservationViewPage from './pages/reservationViewPage/ReservationViewPage';
import ActionPage from './pages/actionPage/ActionPage';
import PolicyPage from './pages/policyPage/PolicyPage';

export interface IAppProps {
};

function App(props: IAppProps): JSX.Element {
  return (
    <Router>
      <Routes>
        <Route path={ROUTER_HOME_PAGE_PATH} element={<HomePage />} />
        <Route path={ROUTER_DASHBOARD_PAGE_PATH} element={<AdminPage panel="reservations" />} />
        <Route path={ROUTER_DASHBOARD_ROOMS_PAGE_PATH} element={<AdminPage panel="rooms" />} />
        <Route path={ROUTER_DASHBOARD_RESERVATIONS_PAGE_PATH} element={<AdminPage panel="reservations" />} />
        <Route path={ROUTER_LOGIN_PAGE_PATH} element={<LoginPage />} />
        <Route path={ROUTER_CONTACT_PAGE_PATH} element={<ContactPage />} />
        <Route path={ROUTER_RESERVATION_PAGE_PATH} element={<ReservationPage />} />
        <Route path={ROUTER_RESERVATION_CONFIRMATION_PAGE_PATH} element={<ReservationConfirmationPage />} />
        <Route path={ROUTER_RESERVATION_VIEW_PAGE_PATH} element={<ReservationViewPage />} />
        <Route path={ROUTER_ACTION_PAGE_PATH} element={<ActionPage />} />
        <Route path={ROUTER_POLICY_PAGE_PATH} element={<PolicyPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;